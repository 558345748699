"use client";

import { Artifact, Page, Site } from "@/types";
import {
  HeroBlockRenderer,
  LiveMarketsBlockRenderer,
  PageLayoutRenderer,
  RendererMapProps,
  TrustpilotRenderer,
  ImageRenderer,
  FastPaymentRenderer,
  IconRenderer,
  StatsBlockRenderer,
  FeatureBlockRenderer,
  ButtonRenderer,
  PlatformBlockRenderer,
  TradeTypeBlockRenderer,
  CTABlockRenderer,
  BreadcrumbsRenderer,
} from "./renderer";

type EngineProps = {
  artifact: Artifact;
  pages?: Page[];
  site?: Site;
};

export const Engine = ({ artifact, pages, site }: EngineProps) => {
  const componentRenderers: RendererMapProps = {
    Hero: HeroBlockRenderer,
    PageLayout: PageLayoutRenderer,
    LiveMarkets: LiveMarketsBlockRenderer,
    Trustpilot: TrustpilotRenderer,
    Image: ImageRenderer,
    FastPayment: FastPaymentRenderer,
    Icon: IconRenderer,
    Stats: StatsBlockRenderer,
    Features: FeatureBlockRenderer,
    Button: ButtonRenderer,
    Platform: PlatformBlockRenderer,
    TradeType: TradeTypeBlockRenderer,
    CTABlock: CTABlockRenderer,
    Breadcrumbs: BreadcrumbsRenderer,
  };

  if (artifact) {
    const { type } = artifact;

    if (!type) {
      return <></>;
    }

    return componentRenderers[type] && componentRenderers[type](artifact);
  }

  return <></>;
};
