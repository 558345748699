"use client";

import { Engine } from ".";
import { iconMapping } from "@/components/Icon/icon-mapping";
import { Button, ButtonProps, FluidContainer } from "@deriv/quill-design";

import {
  TPilotDataProps,
  PageLayout,
  PageLayoutProps,
  Breadcrumbs,
  BreadcrumbsProps,
  LiveMarketContent,
} from "@deriv-com/components";

import truspilotData from "../data/trustpilot.json";

import {
  Artifact,
  ContentBottomSliderProps,
  FastPaymentBlockProps,
  IconComponentProps,
  ImageProps,
} from "@/types";

import {
  FastPayment,
  Hero,
  HeroBlockProps,
  HeroVariants,
  LiveMarketBlock,
  LiveMarketBlockType,
  LiveMarketProps,
  StatBlock,
  SocialProof,
  SocialProofVariants,
  SocialProofVerticalProps,
  SocialProofProps,
  V3StatBlockProps,
  Features,
  FeatureCardProps,
  PlatformBlock,
  PlatformBlockType,
  PlatformBlockCardProps,
  TradeTypeBlockProps,
  TradeTypeBlock,
  FeaturesV4Props,
  ContentLeftProps,
  CTABlockProps,
  CTABlock,
  HomeHeroProps,
} from "@deriv-com/blocks";
import { ReactNode, useEffect, useState } from "react";
import usePricingFeed from "@/services/fetchFirebase";
import {
  EnergyWtiOilusIcon,
  MarketCommodityCopperusdIcon,
  MarketCommodityGoldusdIcon,
  MarketCommodityPalladiumusdIcon,
  MarketCommoditySilverusdIcon,
  StandaloneLocationCrosshairsBoldIcon,
} from "@deriv/quill-icons";
import ExportedImage, { ExportedImageProps } from "next-image-export-optimizer";

export type RendererMapProps = {
  [key: string]:
    | typeof HeroBlockRenderer
    | typeof PageLayoutRenderer
    | typeof LiveMarketsBlockRenderer
    | typeof HeroBlockRenderer
    | typeof PageLayoutRenderer
    | typeof FastPaymentRenderer
    | typeof IconRenderer
    | typeof StatsBlockRenderer
    | typeof FeatureBlockRenderer
    | typeof ButtonRenderer
    | typeof PlatformBlockRenderer
    | typeof CTABlockRenderer
    | typeof BreadcrumbsRenderer
    | typeof ImageRenderer;
};

export const ButtonRenderer = ({ props }: Artifact) => {
  return <Button {...(props as ButtonProps)}></Button>;
};

export const HeroBlockRenderer = ({ props, variant }: Artifact) => {
  const blockVariant = variant as keyof typeof Hero;
  if (blockVariant === "HomeHero") {
    const allProps = props as HomeHeroProps;

    const awardList = (allProps.awards as ImageProps[]).map(
      (award: ImageProps) => {
        return Engine({ artifact: award as unknown as Artifact });
      }
    );

    const Component = Hero[blockVariant];
    return (
      <Component
        {...allProps}
        awards={awardList}
        ctaButton={Engine({
          artifact: allProps.ctaButton as unknown as Artifact,
        })}
        backgroundImage={Engine({
          artifact: allProps.backgroundImage as unknown as Artifact,
        })}
        heroImage={Engine({
          artifact: allProps.heroImage as unknown as Artifact,
        })}
      />
    );
  }

  const Component = Hero[blockVariant];
  const allProps = props as HeroBlockProps;

  const childrenArtifact = allProps.children as Artifact[];

  const contentArtifact = allProps.content as Artifact[];

  const content = contentArtifact?.map((content) => {
    return Engine({
      artifact: content,
    });
  });

  return (
    <Component {...(allProps as HeroBlockProps)} content={content}>
      {childrenArtifact?.map((child) => {
        return Engine({
          artifact: child,
        });
      })}
    </Component>
  );
};

export const FastPaymentRenderer = ({ props }: Artifact) => {
  const allProps = props as FastPaymentBlockProps;

  const cards = allProps.content.cards;

  const cardsData = cards.map((card, i) => ({
    ...card,
    icon: Engine({ artifact: card.icon as unknown as Artifact }),
  }));

  return (
    <FastPayment
      {...allProps}
      content={{
        ...allProps.content,
        cards: cardsData,
      }}
    />
  );
};

export const IconRenderer = ({ props }: Artifact) => {
  const { icon, ...rest } = props as IconComponentProps;

  const DynamicIcon = iconMapping[icon];

  return <DynamicIcon {...rest} />;
};

export const PageLayoutRenderer = ({ props, variant }: Artifact) => {
  const allProps = props as PageLayoutProps;

  const children = allProps.children as Artifact[];

  const contents = children?.map((child) => {
    return Engine({ artifact: child });
  });

  return (
    <PageLayout {...allProps}>{contents.map((content) => content)}</PageLayout>
  );
};

export const ImageRenderer = ({ props }: Artifact) => {
  const { alt, ...allProps } = props as ExportedImageProps;

  return (
    <ExportedImage
      alt={alt}
      height={allProps.height ?? allProps.width}
      placeholder="blur"
      {...allProps}
    />
  );
};

export const TrustpilotRenderer = ({ props, variant }: Artifact) => {
  const { numberOfReviews, trustScore, stars }: TPilotDataProps = truspilotData;
  const Component = SocialProof[variant as keyof SocialProofVariants];

  const allProps =
    variant === "Horizontal"
      ? (props as SocialProofProps)
      : (props as SocialProofVerticalProps);

  const displayText = (allProps?.content as unknown as string[]).map(
    (text: string) => {
      text = text.replace("[trustScore]", trustScore as unknown as string);
      text = text.replace("[numberOfReviews]", numberOfReviews);
      return text;
    }
  );

  return (
    <Component
      {...allProps}
      content={() => displayText}
      data={{
        trustScore,
        numberOfReviews,
        stars,
      }}
      logo={Engine({ artifact: (allProps as any)?.logo as Artifact })}
    />
  );
};

export const LiveMarketsBlockRenderer = ({ props, variant }: Artifact) => {
  const currentVariant = variant as keyof LiveMarketBlockType;
  const [LivePriceData, setLivePriceData] = useState<LiveMarketContent[]>([]);
  const [error, liveMarketsData] = usePricingFeed();

  const comIcons: { [key: string]: ReactNode } = {
    WTI_OIL: <EnergyWtiOilusIcon />,
    XAGUSD: <MarketCommoditySilverusdIcon />,
    XAUUSD: <MarketCommodityGoldusdIcon />,
    XCUUSD: <MarketCommodityCopperusdIcon />,
    XPDUSD: <MarketCommodityPalladiumusdIcon />,
  };
  const handleLiveMarketData = (data: any) => {
    if (data) {
      const markets = data?.row?.mkt;

      if (markets) {
        console.log(markets, "markets");
        const { com } = markets;

        const newLivePriceData: LiveMarketContent[] = [];

        Object.keys(com).forEach((marketKey) => {
          const { code, sym, ask, bid, chng, sprd, mid, ord } = com[marketKey];
          const marketIcon = comIcons[code] || (
            <StandaloneLocationCrosshairsBoldIcon />
          );

          newLivePriceData.push({
            instrumentIcon: marketIcon,
            instrument: sym,
            changePercentage: chng,
            mid: mid,
            bidPrice: bid + "",
            askPrice: ask + "",
            spread: sprd,
            askContent: "Ask",
            bidContent: "Bid",
            buyContent: "Buy",
            sellContent: "Sell",
            spreadContent: "Spread",
            id: ord,
          });
        });

        setLivePriceData(newLivePriceData);
      } else return error;
    }
  };

  useEffect(() => {
    handleLiveMarketData(liveMarketsData);
  }, [liveMarketsData]);

  if (currentVariant === "Slider") {
    const items = props as LiveMarketProps<"LiveMarketCard">;

    if (items) {
      if (items && items.cardSliderProps && items.cardSliderProps.cards) {
        const cards =
          items.cardSliderProps.cards.map((card) => ({
            ...card,
            instrumentIcon: Engine({
              artifact: card.instrumentIcon as unknown as Artifact,
            }),
          })) || [];
        const Component = LiveMarketBlock[currentVariant];

        return (
          <Component
            {...items}
            cardSliderProps={{ ...items.cardSliderProps, cards: LivePriceData }}
          />
        );
      }
    }
  }
};

export const PlatformBlockRenderer = ({ props, variant }: Artifact) => {
  const currentVariant = variant as keyof PlatformBlockType;

  if (currentVariant === "Card") {
    const allProps = props as PlatformBlockCardProps;

    const cardsData = (allProps.cards ?? []).map((card, i) => ({
      ...card,
      icon: Engine({ artifact: card.icon as unknown as Artifact }),
    }));

    return <PlatformBlock.Card {...allProps} cards={cardsData} />;
  }

  return <></>;
};

export const StatsBlockRenderer = ({ props, variant }: Artifact) => {
  if (variant === "V3") {
    const Component = StatBlock[variant];
    return <Component {...(props as V3StatBlockProps)} />;
  }
};

export const CTABlockRenderer = ({ props }: Artifact) => {
  const allProps = props as CTABlockProps;
  const children = Engine({
    artifact: allProps.children as unknown as Artifact,
  });

  const contentArtifact = allProps.content as Artifact[];

  const content = contentArtifact?.map((content) => {
    return Engine({
      artifact: content,
    });
  });

  return (
    <CTABlock {...allProps} content={content}>
      {children}
    </CTABlock>
  );
};

export const FeatureBlockRenderer = ({ props, variant }: Artifact) => {
  const blockVariant = variant as keyof typeof Features;

  if (blockVariant === "ContentSlider") {
    const allProps = props as ContentBottomSliderProps;
    const cards = allProps.cardSliderProps.cards;

    const cardsData = cards?.map((card, i) => ({
      ...card,
      icon: Engine({ artifact: card.icon as unknown as Artifact }),
    }));

    const Component = Features[blockVariant];
    return (
      <Component
        {...allProps}
        cardSliderProps={{
          ...allProps.cardSliderProps,
          cards: cardsData,
          slideClasses: "max-w-[400px] !h-auto !mr-gap-none !ml-gap-none",
          className: "w-screen lg:w-full [&>*:nth-child(1)]:!gap-gap-lg",
        }}
      />
    );
  }

  if (blockVariant === "Card") {
    const allProps = props as FeatureCardProps;
    const cards = allProps.cards;

    const cardsData = cards?.map((card) => ({
      ...card,
      content: Engine({ artifact: card.content as unknown as Artifact }),
      icon: Engine({ artifact: card.icon as unknown as Artifact }),
    }));

    const ctaNode = allProps.cta ? (
      <div className="hidden lg:block">
        {Engine({ artifact: allProps.cta as unknown as Artifact })}
      </div>
    ) : null;

    const Component = Features[blockVariant];
    return <Component {...allProps} cards={cardsData} cta={ctaNode} />;
  }

  if (blockVariant === "V4") {
    const allProps = props as FeaturesV4Props;

    let v4Props = {
      ...allProps,
      content: Engine({ artifact: allProps.content as unknown as Artifact }),
      children:
        allProps.children &&
        Engine({ artifact: allProps.children as unknown as Artifact }),
    };

    return <Features.V4 {...v4Props} />;
  }

  if (blockVariant === "V5") {
    const allProps = props as FeaturesV4Props;

    const v5Props = {
      ...allProps,
      content: Engine({ artifact: allProps.content as unknown as Artifact }),
      children:
        allProps.children &&
        Engine({ artifact: allProps.children as unknown as Artifact }),
    };

    return <Features.V5 {...v5Props} />;
  }

  if (blockVariant === "ContentLeft") {
    const allProps = props as ContentLeftProps;

    const ClProps = {
      ...allProps,
      content: () =>
        Engine({ artifact: allProps.content as unknown as Artifact }),
      children: Engine({ artifact: allProps.children as unknown as Artifact }),
    };

    return <Features.ContentLeft {...ClProps} />;
  }

  if (blockVariant === "ContentRight") {
    const allProps = props as ContentLeftProps;

    const CrProps = {
      ...allProps,
      content: () =>
        Engine({ artifact: allProps.content as unknown as Artifact }),
      children: Engine({ artifact: allProps.children as unknown as Artifact }),
    };

    return <Features.ContentRight {...CrProps} />;
  }

  return <></>;
};

export const TradeTypeBlockRenderer = ({ props, variant }: Artifact) => {
  const allProps = props as TradeTypeBlockProps<"ContentBottom">;

  const cards = allProps?.cards.map((card) => {
    return {
      ...card,
      icon: Engine({ artifact: card.icon as unknown as Artifact }),
    };
  });

  return <TradeTypeBlock {...allProps} cards={cards} />;
};

export const BreadcrumbsRenderer = ({ props }: Artifact) => {
  const allProps = props as BreadcrumbsProps;
  return (
    <FluidContainer>
      <Breadcrumbs {...allProps} />
    </FluidContainer>
  );
};
